import type { FC, ReactNode } from 'react';
import NextHead from 'next/head';
import { useRouter } from 'next/router';

import { useLocalizer } from '../localization';
import { BooleanValue } from '../entities/common';

interface Props {
  readonly noIndex?: boolean | BooleanValue;
  readonly title: string;
  readonly description: string;
  readonly image?: string;
}

/**
 * todo #182884739 populate these
 * <meta property="og:site_name" content="" />
 * <meta name="twitter:card" content="summary_large_image" />
 * <meta name="twitter:site" content="@yourname" />
 * <meta name="twitter:title" content="" />
 * <meta name="twitter:description" content="" />
 * <meta name="twitter:image" content="" />
 */
const Head: FC<Props> = ({ noIndex, title, description, image }) => {
  const standardizedTitle = title !== '' ? `${title} | Moov` : 'Moov';

  const router = useRouter();
  const loc = useLocalizer();
  const locale = loc.getLocale();
  const domainLocales = router.domainLocales ?? [];

  // Next fails to properly render components inside of Head, use built-in elements only
  const alternates: ReactNode[] = [];
  for (const domainLocale of domainLocales) {
    if (domainLocale.defaultLocale === locale) {
      alternates.push(
        <link key="x-default" rel="alternate" href={`https://${domainLocale.domain}${router.asPath}`} hrefLang="x-default" />
      );
    }
    alternates.push(
      <link
        key={domainLocale.defaultLocale}
        rel="alternate"
        href={`https://${domainLocale.domain}${router.asPath}`}
        hrefLang={domainLocale.defaultLocale}
      />
    );
  }

  return (
    <NextHead>
      <meta name="robots" content={noIndex === true || noIndex === 1 ? 'noindex' : 'follow, index'} />
      <title>{standardizedTitle}</title>
      <meta property="og:title" content={standardizedTitle} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}
      {alternates}
    </NextHead>
  );
};

export default Head;
